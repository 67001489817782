import React from "react"

const RecoveredVideo = ({ video }) => (
  <article className="recovered-video">
    <h2 className="text__m recovered-video-title">{video.title}</h2>
    <p className="recovered-video-description">{video.description}</p>
    <p className="recovered-video-info">
      <span className="recovered-video-state">{video.state}</span>
      <span className="recovered-video-id">ID: {video.id}</span>
    </p>
  </article>
)

export default RecoveredVideo
