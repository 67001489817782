import React from "react"
import { Link } from "gatsby"
import keyBy from "lodash.keyby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FileInput from "../components/FileInput"
import RecoveredVideo from "../components/RecoveredVideo"

import DeletedVideoImg from "../images/icon-deleted.svg"
import PrivateVideoImg from "../images/icon-private.svg"

const VIDEO_DELETED = "video-deleted"
const VIDEO_PRIVATE = "video-private"

class RecoverVideosPage extends React.Component {
  constructor(props) {
    super(props)

    this.recoverVideos = this.recoverVideos.bind(this)

    this.state = {
      lostVideos: null,
      isRecoveringVideos: false,
    }

    this.oldPlaylistInputRef = React.createRef()
    this.latestPlaylistInputRef = React.createRef()
  }

  recoverVideos(e) {
    e.preventDefault()
    e.stopPropagation()

    window.gtag &&
      window.gtag("event", "click", {
        event_category: "Button",
        event_label: "Recover videos",
      })

    this.setState({ lostVideos: null, isRecoveringVideos: true })

    Promise.all([
      this.readPlaylist(this.oldPlaylistInputRef.current.files[0]).then(
        this.parsePlaylistFile
      ),
      this.readPlaylist(this.latestPlaylistInputRef.current.files[0]).then(
        this.parsePlaylistFile
      ),
    ]).then(([oldPlaylist, latestPlaylist]) => {
      this.setState({
        lostVideos: this.findLostVideos(oldPlaylist, latestPlaylist),
        isRecoveringVideos: false,
      })
    })
  }

  readPlaylist(file) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      const onFileLoaded = () => {
        fr.onload = null
        resolve(fr.result)
      }

      fr.onload = onFileLoaded
      fr.readAsText(file)
    })
  }

  parsePlaylistFile(fileContent = null) {
    try {
      const playlist = JSON.parse(fileContent)

      if (!Array.isArray(playlist)) {
        throw new Error("Invalid playlist format")
      }

      return playlist
    } catch (error) {
      return []
    }
  }

  findLostVideos(oldPlaylist, latestPlaylist) {
    const latestPlaylistByVideoId = keyBy(
      latestPlaylist,
      "contentDetails.videoId"
    )
    const lostVideos = []

    oldPlaylist.forEach(item => {
      const videoId = item.contentDetails?.videoId

      if (!latestPlaylistByVideoId[videoId]) {
        lostVideos.push({ ...item.snippet, id: videoId, state: VIDEO_DELETED })
      } else if (
        latestPlaylistByVideoId[videoId].status?.privacyStatus === "private" &&
        item.status?.privacyStatus !== "private"
      ) {
        lostVideos.push({ ...item.snippet, id: videoId, state: VIDEO_PRIVATE })
      }
    })

    return lostVideos
  }

  updateRecoveryProgress() {}

  render() {
    const videoStateImages = {
      [VIDEO_DELETED]: <img src={DeletedVideoImg} alt="Deleted video" />,
      [VIDEO_PRIVATE]: <img src={PrivateVideoImg} alt="Private video" />,
    }

    return (
      <Layout mainClassName="recover-videos-page">
        <SEO
          title="Recover YouTube Videos"
          description="Recover informations about private or deleted YouTube videos in your YouTube playlist using backup"
          pathname={this.props.location.pathname}
        />
        <section className="intro">
          <div className="inner">
            <div style={{ color: "red", fontWeight: "bold", fontSize: "24px" }}>
              !! Currently not working because of Google Takeout exported data
              change !!
              <br />
              <br />
            </div>
            <h1 className="text__l">
              Let's go recover your YouTube videos from taken{" "}
              <Link to="/backup-videos/">backup</Link>
            </h1>
          </div>
        </section>
        <section className="steps">
          <div className="angle-start"></div>
          <div className="content">
            <div className="inner">
              <div className="row">
                <div className="col">
                  <h2 className="text__m">Attach backup</h2>
                  <p>
                    Attach the backup file of YouTube playlist you want to
                    recover videos from. Don't worry, nothing is uploaded to the
                    server.
                  </p>
                </div>
                <div className="col">
                  <FileInput
                    ref={this.oldPlaylistInputRef}
                    inputOptions={{
                      form: "recover-videos-form",
                      accept: "application/json",
                      required: true,
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h2 className="text__m">Make new backup</h2>
                  <p>
                    In order to find deleted or private videos, you need to make
                    another <Link to="/backup-videos/">backup</Link> of the
                    current state of your YouTube playlist and attach it here so
                    these two can be compared.
                  </p>
                </div>
                <div className="col">
                  <FileInput
                    ref={this.latestPlaylistInputRef}
                    inputOptions={{
                      form: "recover-videos-form",
                      accept: "application/json",
                      required: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="angle-end"></div>
        </section>
        <section className="recovery">
          <div className="inner">
            <form id="recover-videos-form" onSubmit={this.recoverVideos}>
              <button
                className="btn__primary"
                disabled={this.state.isRecoveringVideos}
              >
                {this.state.isRecoveringVideos
                  ? "Recovering"
                  : "Recover videos"}
              </button>
            </form>
            {Array.isArray(this.state.lostVideos) ? (
              this.state.lostVideos.length > 0 ? (
                <div className="recovered-videos">
                  {this.state.lostVideos.map(video => (
                    <RecoveredVideo
                      key={video.id}
                      video={{ ...video, state: videoStateImages[video.state] }}
                    />
                  ))}
                </div>
              ) : (
                <p className="text__l no-recovered-videos">
                  No private/deleted videos or recovery not possible
                </p>
              )
            ) : null}
          </div>
        </section>
      </Layout>
    )
  }
}

export default RecoverVideosPage
