import React, { useState } from "react"

const FileInput = React.forwardRef(
  ({ className = "", inputOptions = {} }, ref) => {
    const [filename, setFilename] = useState("")
    const onChange = () => {
      const files = ref.current.files
      const fname = files.length > 0 ? files[0].name : ""

      setFilename(fname)

      if (typeof inputOptions.onChange === "function") {
        inputOptions.onChange()
      }
    }

    return (
      <label
        className={`file-input ${className}`}
        data-filename={filename || "No file chosen"}
      >
        <input {...inputOptions} type="file" ref={ref} onChange={onChange} />
      </label>
    )
  }
)

export default FileInput
